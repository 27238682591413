export const environment = {
  production: true,
  API_ENDPOINT: 'https://accounts.mettholidays.ae/v2/api/',
  // API_ENDPOINT: 'http://3.29.100.110:3001/v2/api/', // LIVE IP
  // API_ENDPOINT: 'http://3.29.100.110:3000/v2/api/', // TEST IP
  ADMIN_ID: 'a165fccd-2bbe-4391-9ea9-a237b1fb7c82',
  SUB_ADMIN_ID: 'e5e069e0-1914-4fc9-bd4a-90487fce89ea',
  // firebaseConfig: {
  //   apiKey: 'AIzaSyCR-3WZBUiZuSSgdoH63x5WSUsGSSFBtjU',
  //   authDomain: 'vendor-5c444.firebaseapp.com',
  //   projectId: 'vendor-5c444',
  //   storageBucket: 'vendor-5c444.appspot.com',
  //   messagingSenderId: '1063137373466',
  //   appId: '1:1063137373466:web:30d81cb6394987422450a3',
  //   measurementId: 'G-03NWFZSKSS',
  // },
};
